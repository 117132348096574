


























































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { FieldDescription } from '../../models/_crud';

@Component({ components: { flatPickr } })
export default class EntityField extends Vue {
  // Input option 1: field object from entity description
  @Prop() field?: any;

  // Input option 2: full entity description and field key
  @Prop() entityDescription?: FieldDescription[];
  @Prop() fieldKey?: string;

  @Prop() entity: any;
  @Prop({ default: false }) readOnly: boolean;

  flatPickrConfig = {
    enableTime: true,
    dateFormat: "Z",
    altInput: true,
    altFormat: "d/m/Y H:i",
  };

  editEnabled = false;

  get fieldDefinition() {
    if (this.field) {
      return this.field;
    }
    if (this.entityDescription && this.fieldKey) {
      return this.entityDescription.find(field => field.key === this.fieldKey);
    }
    return {};
  }

  async mounted() {
    if (this.field?.kind === "relation") {
      await this.loadManyToOneOptions(this.field);
    }
  }

  async loadManyToOneOptions(field: any) {
    if (this.fieldDefinition.model) {
      const searchOptions: any = {};
      const manyToOneModelDescription = await this.fieldDefinition.model.getDescription();
			if (manyToOneModelDescription.find((field: any) => field.key === 'name')) {
				searchOptions.sort = [{ field: 'name', order: 'ASC'}];
			}
      const options = await this.fieldDefinition.model.search(searchOptions, null, this.fieldDefinition.key);
      for (const option of options) {
        option.label = this.fieldDefinition.model.labelTransformer ? await this.fieldDefinition.model.labelTransformer(option) : option.name;
      }
      this.fieldDefinition.manyToOneOptions = options;
    }
  }

  uploadCompleted(event: any, fieldKey: string) {
    this.$vs.loading.close();
    this.$vs.notify({ color: "success", title: "Sucesso", text: "O arquivo foi enviado" });
    const response = JSON.parse(event.target?.response);
    this.entity[fieldKey] = response.uuid;
  }

  uploadError(event: any) {
    this.$vs.loading.close();
    console.error("Upload error", event);
    this.$vs.notify({ color: "danger", title: "Erro", text: "Ocorreu um erro ao enviar o arquivo" });
  }

  openFile(fileUrl: string) {
    window.open(fileUrl, "_blank");
  }

  fileChanged() {
    this.$vs.loading();
  }
}
