





































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { endOfMonth, startOfMonth } from "date-fns";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import EntityGallery from "./EntityGallery.vue";

export class FilterDefinitionField {
  fieldKey: string;
  label?: string;
  kind?: "text" | "date_interval" | "select_multiple";
  defaultSelectOptions?: string[];
}

export type FilterDefinition = FilterDefinitionField[];

@Component({ components: { flatPickr } })
export default class EntityFilter extends Vue {
  @Prop() model: any;
  @Prop() filterDefinition: FilterDefinition;
  @Prop({ default: () => ({}) }) filterEntity: any;

  entityDescription: any = [];

  flatPickrConfig = {
    enableTime: false,
    dateFormat: "Z",
    altInput: true,
    altFormat: "d/m/Y",
  };

  async mounted() {
    this.$vs.loading();
    try {
      this.entityDescription = await this.model.getDescription();

      // initialize filterEntity with default values
      this.filterFields.forEach(filterField => {
        switch (filterField.kind) {
          case "date_interval":
            this.$set(this.filterEntity, filterField.key, {
              $between: [startOfMonth(new Date()), endOfMonth(new Date())],
            });
            break;
          case "select_multiple":
            this.$set(this.filterEntity, filterField.key, { $in: filterField.defaultSelectOptions || [] });
            break;
          default:
            this.$set(this.filterEntity, filterField.fieldDefinition.relationType === "many-to-one" ? `${filterField.key}.name` : filterField.key, { $cont: "" });
        }
      });

    } catch (error) {
      console.error(error);
    }
    this.$vs.loading.close();
  }

  get filterFields() {
    if (!this.entityDescription || !this.filterDefinition) {
      return [];
    }
    return this.filterDefinition.map((filterDefinitionField) => {
      const fieldDefinition = this.entityDescription.find((field: any) => field.key === filterDefinitionField.fieldKey) || {};

      const filterField = {
        ...filterDefinitionField,
        key: filterDefinitionField.fieldKey,
        fieldDefinition,
      };

      // select filter kind based on field kind
      if (!filterField.kind) {
        switch (fieldDefinition.kind) {
          case "date":
          case "datetime":
            filterField.kind = "date_interval";
            break;
          case "select":
            filterField.kind = "select_multiple";
            break;
          default:
            filterField.kind = "text";
        }
      }

      return filterField;
    });
  }

}
