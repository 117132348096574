

























import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { format, parseISO } from "date-fns";
import debounce from "debounce";
import XLSX from 'xlsx';

@Component({})
export default class EntityTable extends Vue {
  @Prop() title: string;
  @Prop() model: any;
  @Prop() columns: string[];
  @Prop({ default: 20 }) pageLimit: number;
  @Prop({ default: () => {} }) filter: any;
  @Prop({ default: true }) enableCreation: boolean;
  @Prop({ default: false }) readOnly: boolean;
  @Prop({ default: false }) multiple: boolean;

  @Prop({ default: () => [] }) actions: {
    name: string,
    icon: string,
    color: string,
    action: any,
  }[];

  entityDescription: any = [];

  // current search state
  currentPage: number = 1;
  currentSearchTerm: string = "";
  currentSortKey: string = "";
  currentSortDirection: string | null = null;
  currentTotal: number = 0;

  results: any = [];
  selectedRows: any = []; // if multiple selection is disable, this becomes an object

  created() {
    this.handleSearch = debounce(this.handleSearch, 400);
  }

  async mounted() {
    this.entityDescription = await this.model.getDescription();
    this.handleSort("", null); // initialize default sort and perform initial search
  }

  get tableFields(): any[] {
    if (!this.entityDescription) {
      return [];
    }
    return this.entityDescription.filter((fieldDescription: any) => this.columns.includes(fieldDescription.key));
  }

  getFieldContent(field: any, fieldKey: string, fieldData: any) {
    switch (field.kind) {
      case "select":
        return fieldData[fieldKey] ? field.options.find((option: any) => option.value === fieldData[fieldKey]).name : "-";
      case "date":
        return fieldData[fieldKey] ? format(parseISO(fieldData[fieldKey]), "dd/MM/yyyy HH:mm") : "-";
      case "relation":
        return fieldData[`${fieldKey}.name`];
    }
    return fieldData[fieldKey];
  }

  async handleSearch(term: string) {
    if (!this.currentSortKey) {
      return;
    }
    this.currentSearchTerm = term;
    const queryParameters = {
      page: this.currentPage,
      limit: this.pageLimit,
      sort: [{
        field: this.currentSortKey,
        order: this.currentSortDirection,
      }],
      search: {
        ...this.filter,
        $or: this.tableFields.filter(field => field.kind !== 'relation' && field.kind !== 'action').map(field => ({
          [field.key]: {
            $cont: this.currentSearchTerm,
          },
        })),
      },
      join: this.entityDescription.filter((fieldDescription: any) => fieldDescription.kind === 'relation' && this.tableFields.find(field => fieldDescription.key === field.key || fieldDescription.key === field.join)).map((fieldDescription: any) => ({ field: fieldDescription.key })),
    };
    this.$vs.loading();
    try {
      const response = await this.model.search(queryParameters);
      this.currentTotal = response.total;
      // keep reference to currently selected rows by id
      this.results = response.data.map((entity: any) => this.selectedRows.find((row: any) => row.id === entity.id) || entity);
      // padding workaround due to broken server side table implementation
      // const leftPaddedResults = Array(this.pageLimit * (this.currentPage - 1)).map(() => ({})).concat(response.data); // left pad results with blank rows
      // this.results = leftPaddedResults.concat(Array(this.currentTotal - leftPaddedResults.length).map(() => ({}))); // right pad results until total length is reached
    } catch (error) {
      console.error(error);
    }
    this.$vs.loading.close();
  }

  async exportSearch() {
			const queryParameters = {
        sort: [{
          field: this.currentSortKey,
          order: this.currentSortDirection,
        }],
        search: {
          ...this.filter,
          $or: this.tableFields.filter(field => field.kind !== 'relation' && field.kind !== 'action').map(field => ({
            [field.key]: {
              $cont: this.currentSearchTerm,
            },
          })),
        },
        join: this.entityDescription.filter((fieldDescription: any) => fieldDescription.kind === 'relation' && this.tableFields.find(field => fieldDescription.key === field.key || fieldDescription.key === field.join)).map((fieldDescription: any) => ({ field: fieldDescription.key })),
      };

    this.$vs.loading();
    try {
      const exportData = await this.model.search(queryParameters);
      if (!exportData.length) {
				return;
			}
			const fieldsToExport = this.tableFields;
			// replace header names and translate select options
			const xlsData = exportData.map((row: any) => fieldsToExport.reduce((processedRow, field) => ({
				...processedRow,
				[field.name]: this.getFieldContent(field, field.key, row),
			}), {}));
			const exportWS = XLSX.utils.json_to_sheet(xlsData, { header: fieldsToExport.map(field => field.name) });
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, exportWS, 'Relatório');
			XLSX.writeFile(wb, 'relatorio.xlsx');

    } catch (error) {
      console.error(error);
    }
    this.$vs.loading.close();
  }

  async handleChangePage(page: number) {
    this.currentPage = page;
    await this.handleSearch(this.currentSearchTerm);
  }

  async handleSort(key: string, direction: string | null) {
    if (direction) {
      this.currentSortDirection = direction.toUpperCase();
      this.currentSortKey = key;
    } else {
      this.currentSortDirection = "ASC";
      this.currentSortKey = ((this.tableFields.find(field => field.defaultSort) || this.tableFields[0]) || {}).key;
    }
    this.handleSearch(this.currentSearchTerm);
  }

  @Watch("selectedRows")
  rowsChanged(newRows: any, oldRows: any) {
    if (this.multiple !== false) {
      this.$emit("rowsSelected", newRows);
      return;
    }
    if (newRows && newRows.id !== oldRows.id) {
      this.$router.push(`${this.$router.currentRoute.path}/${newRows.id}`);
    }
  }

  create() {
    this.$router.push(`${this.$router.currentRoute.path}/novo`);
  }

  runAction(event: any, action: any, entityData: any) {
    event.stopPropagation();
    action(entityData);
  }

  async refresh() {
    await this.handleSearch(this.currentSearchTerm);
  }
}
