import { crudQuery, crudRead, crudUpdate, crudDelete, crudDescription, crudCreate, crudMetrics, crudPost, crudGet } from "./_request";

export class CrudModel {
	public endpoint: string;
	public description: any;
	public additionalDescription: any = [];
	public relationModels: { fieldKey: string, model: any }[] = [];

	constructor(endpoint: string, additionalDescription: FieldDescription[] = [], relationModels: { fieldKey: string, model: any }[] = []) {
		this.endpoint = endpoint;
		this.additionalDescription = additionalDescription;
		this.relationModels = relationModels;
	}

	async getDescription(): Promise<EntityDescription> {
		if (!this.description) {
			this.description = (await crudDescription(this.endpoint)).concat(this.additionalDescription);
			this.description.forEach((fieldDescription: any) => {
				if (fieldDescription.kind === "relation") {
					fieldDescription.model = this.relationModels.find(relationModel => relationModel.fieldKey === fieldDescription.key)?.model;
					fieldDescription.manyToOneOptions = [];
				}
			});
		}
		return this.description;
	}

	async create(data: any) {
		try {
			return (await crudCreate(this.endpoint, data)); // await before return allows error catching
		} catch (error) {
			await this.handleError(error);
		}
	}

	async search(queryParameters: any, joinAll = false, joinKey: string | null = null, joinBlacklist: string[] = []) {
		if (joinAll) {
			queryParameters.join = await this.getJoinableFields();
		} else if (joinKey) {
			const description = await this.getDescription();
			queryParameters.join = description
				.filter((field: any) => field.kind === "relation" && field.key.includes(joinKey) && !joinBlacklist.find(blacklistedKey => field.key.includes(blacklistedKey)))
				.map((field: any) => ({ field: field.key }));
		}
		return crudQuery(this.endpoint, queryParameters);
	}

	async read(id: string | number, joinKey: string | null = null, joinBlacklist: string[] = []) {
		const queryParameters: { join?: any[] } = {};
		if (joinKey) {
			const description = await this.getDescription();
			queryParameters.join = description
				.filter((field: any) => field.kind === "relation" && field.key.includes(joinKey) && !joinBlacklist.find(blacklistedKey => field.key.includes(blacklistedKey)))
				.map((field: any) => ({ field: field.key }));
		} else {
			queryParameters.join = await this.getJoinableFields();
		}
		return crudRead(this.endpoint, id, queryParameters);
	}

	async update(id: string | number, data: any) {
		try {
			return (await crudUpdate(this.endpoint, id, data)); // await before return allows error catching
		} catch (error) {
			await this.handleError(error);
		}
	}

	async delete(id: string | number, auditNotes: string) {
		return crudDelete(this.endpoint, id, auditNotes);
	}

	async post(id: string | number | null, action: string, data?: any) {
		const entityId = id ? `${id}/` : '';
		return crudPost(this.endpoint, `${entityId}${action}`, data);
	}

	async get(path: string, params?: any) {
		return crudGet(this.endpoint, path, params);
	}

	async getMetrics(metricsOptions: { groupBy: "DAY" | "WEEK" | "MONTH", startDate: string, endDate: string }, subEntity: string = "") {
		return crudMetrics(this.endpoint, metricsOptions, subEntity);
	}

	private async getJoinableFields(): Promise<{field: string}[]> {
		const description = await this.getDescription();
		let relationFields = description.filter((field: any) => field.kind === "relation");
		// remove one-to-many and many-to-many relations
		const toManyRelationsKeys = relationFields.filter((field: any) => ["one-to-many", "many-to-many"].includes(field.relationType)).map((field: any) => field.key);
		toManyRelationsKeys.forEach((key: string) => {
			relationFields = relationFields.filter((field: any) => !field.key.includes(key));
		});
		return relationFields.map((field: any) => ({ field: field.key }));
	}

	private async handleError(error: any) {
		const description = await this.getDescription();
		switch (error.statusCode) {
			case 400: {
				const flatErrors = flattenValidationErrors(error.message);
				error.flatErrors = flatErrors;

				const propertyKeys: string[] = flatErrors.filter(error => error.constraints).map(error => error.property);
				const propertyNames = propertyKeys.map(key => description.find((field: any) => field.key === key)?.name || key);
				error.friendlyMessage = `Os seguintes campos possuem erros:<br>${propertyNames.join(', ')}`;
			}
			break;
		}
		throw error;
	}
}

export class ValidationError {
	property: string;
	target: any;
	value: any;
	children?: ValidationError[];
	constraints?: any;
}

function flattenValidationErrors(errors: any, prefix: string = ''): { property: string, constraints: any}[] {
	let flattenedErrors: any[] = [];
	errors.forEach((error: any) => {
		const errorProperty = prefix ? `${prefix}.${error.property}` : error.property;
		flattenedErrors.push({
			property: errorProperty,
			constraints: error.constraints,
		});
		if (error.children) {
			flattenedErrors = flattenedErrors.concat(flattenValidationErrors(error.children, errorProperty));
		}
	});
	return flattenedErrors;
}

export class FieldDescription {
	name: string;
	key: string;
	kind?: 'text' | 'textarea' | 'select' | 'password' | 'date' | 'datetime' | 'file' | 'relation' | 'color' | 'checkbox' | 'money';
	relationType?: 'one-to-one' | 'many-to-one' | 'one-to-many' | 'many-to-many' | 'embedded';
	options?: { name: string, value: string}[];
	displayInResults?: boolean;
	defaultSort?: 'asc' | 'desc';
	vueMask?: string;
	validation?: {
		lengthMin?: number,
		lengthMax?: number,
		min?: number,
    max?: number,
    matchKey?: string,
		kind?: 'string' | 'integer' | 'decimal' | 'cpfOrCnpj' | 'cpf' | 'cnpj' | 'email' | 'phone' | 'url' | 'match',
	}
}

export type EntityDescription = FieldDescription[];
