








import { Component, Vue, Watch, Prop } from "vue-property-decorator";

import EntityFilter, { FilterDefinition } from "./EntityFilter.vue";
import EntityTable from "./EntityTable.vue";
import EntityForm from "./EntityForm.vue";

@Component({
  components: { EntityFilter, EntityTable, EntityForm },
})
export default class EntityCrud extends Vue {
  @Prop() model: any;

  @Prop() title: string;
  @Prop() tableColumns: any[];
  @Prop() filter: any;

  id: any = "";
  @Prop() filterDefinition: FilterDefinition;
  @Prop() formColumns: any[];
  @Prop() defaultEntity: any;
  @Prop({ default: false }) readOnly: boolean;
  @Prop({ default: () => [] }) actions: {
    kind: 'TABLE' | 'FORM' | 'ALL',
    name: string,
    icon: string,
    color: string,
    action: any,
  }[];
  @Prop({ default: () => [] }) galleries: {
    name: string,
    entityKey: string,
    mediaEntityModel: any,
    mediaEntityFilter: any,
    mediaEntityOrderKey: string,
    mediaEntityMediaUrlKey: string,
  }[];

  filterEntity: any = {};

  async mounted() {
    this.id = this.$route.params.id;
  }

  @Watch("$route")
	updateRoute(route: any) {
		this.id = route.params.id || "";
  }

  get tableActions() {
    return this.actions.filter(action => action.kind === 'TABLE' || action.kind === 'ALL');
  }

  get formActions() {
    return this.actions.filter(action => action.kind === 'FORM' || action.kind === 'ALL');
  }

  get combinedFilter() {
    return { ...this.filter, ...this.filterEntity };
  }

  refresh() {
    (this.$refs.crudTable as any)?.refresh();
  }
}
