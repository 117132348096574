























import { Component, Vue, Prop } from "vue-property-decorator";
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

type StepState = 'idle' | 'in_progress' | 'done';

@Component({ components: { ClipLoader }})
export default class ProjectDeployer extends Vue {
  @Prop()
  project: any;

  @Prop()
  model: any;

  steps: { title: string; relatedEntityKey: string; functionName: string; selected?: boolean; state?: StepState }[] = [
    { title: 'Criar bucket', relatedEntityKey: 'bucketProd', functionName: 'createStorageBucket' },
    { title: 'Criar serviço do backend', relatedEntityKey: 'apiProdUrl', functionName: 'createBackendService'  },
    { title: 'Criar serviço do frontend', relatedEntityKey: 'frontendProdUrl', functionName: 'createFrontendService'  },
    { title: 'Criar serviço do painel', relatedEntityKey: 'adminProdUrl', functionName: 'createAdminService'  },
  ];

  deployInProgress = false;

  async mounted() {
    this.steps.forEach(step => {
      this.$set(step, 'selected', false);
      this.$set(step, 'state', 'idle');
    });
  }

  get availableSteps() {
    return this.steps.filter(step => !this.project[step.relatedEntityKey]);
  }

  get deployDisabled() {
    return !this.availableSteps.filter(step => step.selected).length;
  }

  async runDeploy() {
    this.deployInProgress = true;
    const stepsToRun = this.availableSteps.filter(step => step.selected && step.state === 'idle');

    try {
      for (const step of stepsToRun) {
        step.state = 'in_progress';
        console.log('model', this.model);
        await (this.model as any)[step.functionName](this.project.id);
        step.state = 'done';
      }
    } catch (error) {
      this.$vs.notify({ title: "Erro", text: "Ocorreu um erro durante no deploy", iconPack: "feather", icon: "icon-alert-circle", color: "danger" });
      console.error(error);
    }

    this.deployInProgress = false;
    this.$vs.notify({ title: "Deploy finalizado", text: "Os passos selecionados foram executados", iconPack: "feather", icon: "icon-check", color: "success" });
    this.$emit('deployed');
  }
}
