






















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import draggable from 'vuedraggable'

import { uploadFile } from '@/models/_request';
import { CrudModel } from '@/models/_crud';

@Component({ components: { draggable }})
export default class EntityGallery extends Vue {
  @Prop() name: string;

  // main entity properties
  @Prop() entityId: string;
  @Prop() entityKey: string;

  // media entity properties
  @Prop() mediaEntityModel: CrudModel;
  @Prop({ default: () => ({}) }) mediaEntityFilter: any;
  @Prop() mediaEntityOrderKey: string;
  @Prop() mediaEntityMediaUrlKey: string;

  galleryFiles: any[] = [];

  async mounted() {
    await this.loadGallery();
  }

  async loadGallery() {
    if (!this.entityId) {
      return;
    }
    this.galleryFiles = await this.mediaEntityModel.search({
      sort: [{
        field: this.mediaEntityOrderKey,
        order: "ASC",
      }],
      search: {
        ...this.mediaEntityFilter,
        [`${this.entityKey}Id`]: this.entityId, // Id concatenation due to @nestjsx/crud weirdness
      },
    });
  }

  isUploading = false;
  async uploadFiles(fileList: FileList) {
    const files = Array.from(fileList);
    this.isUploading = true;
    this.$vs.loading();
    try {
      await Promise.all(files.map(async file => {
        const uploadResult = await uploadFile(file);
        console.log(uploadResult);
        await this.mediaEntityModel.create({
          ...this.mediaEntityFilter,
          [this.entityKey]: this.entityId,
          [this.mediaEntityMediaUrlKey]: uploadResult.uuid,
          [this.mediaEntityOrderKey]: 1,
        });
      }));
      this.$vs.notify({ title: "Sucesso", text: "A mídia foi adicionada", color: "success" });
      await this.loadGallery();
    } catch (error) {
      this.$vs.notify({ title: "Erro", text: "Ocorreu um erro durante o upload.", iconPack: "feather", icon: "icon-alert-circle", color: "danger" });
    }
    this.isUploading = false;
    this.$vs.loading.close();
  }

  async removeFile(file: any) {
    if (!(await new Promise(resolve => {
      this.$vs.dialog({
        color: "danger",
        title: `Confirma a exclusão?`,
        text: "Não será possível reverter essa operação.",
        type: "confirm",
        acceptText: "Excluir",
        cancelText: "Cancelar",
        accept: () => resolve(true),
        cancel: () => resolve(false),
      });
    }))) {
      return;
    }

    this.$vs.loading();
    try {
      await this.mediaEntityModel.delete(file.id, "");
      this.$vs.notify({ title: "Sucesso", text: "A mídia foi excluída.", iconPack: "feather", icon: "icon-check", color: "success" });
      await this.loadGallery();
    } catch (error) {
      console.error(error);
      this.$vs.notify({ title: "Erro", text: "Ocorreu um erro. Tente novamente.", iconPack: "feather", icon: "icon-alert-circle", color: "danger" });
    }
    this.$vs.loading.close();
  }

  async orderChanged() {
    if (!(this.mediaEntityModel as any).reorder) {
      return;
    }
    this.$vs.loading();
    try {
      await (this.mediaEntityModel as any).reorder(this.galleryFiles.map(file => ({ id: file.id })));
      this.$vs.notify({ title: 'Sucesso', text: "Ordem alterada", color: "success" });
    } catch (error) {
      console.error(error);
      this.$vs.notify({ title: 'Erro', text: "Erro ao alterar ordem", color: "error" });
    }
    this.$vs.loading.close();
  }
}
